<template>
	<section class="content">
		<div class="card card-info">
			<div class="card-body">
				<div class="row mb-3" style="row-gap: 15px;">
					<div class="col-auto">
						<div class="input-group">
							<input type="text" ref="daterange" class="form-control" style="border-right: 0" />
							<div class="input-group-append">
							<div class="input-group-text" style="background-color: #fff" >
								<span><span class="fa fa-calendar"></span></span>
							</div>
							</div>
						</div>
					</div>
					<div class="col-auto">
						<select class="form-control" v-model="filter.status" @change="getStatus">
							<option value="" disabled>Status Withdraw</option>
							<option value="all">Semua</option>
							<option v-for="row in status" v-bind:key="row.code" v-bind:value="row.code">
								{{ row.label }}
							</option>
						</select>
					</div>
					<div class="col-auto mb-2">
						<button class="btn btn-default bg-white" @click="selectStatus">Filter Withdraw <i class="fi fi-rr-angle-small-down"></i></button>
					</div>
					<div class="col-auto">
						<div class="btn btn-light">Jumlah Data : {{ allWithdraw.total }}</div>
					</div>
					<div class="col-auto ml-auto">
						<div class="btn-group" ref="toolbar">
						<button type="button" data-action="read" class="btn btn-default"><i class="fa fa-sync-alt"></i></button>
						<button type="button" data-action="export-xlsx" class="btn btn-default" :disabled="processing" v-on:click="downloadXlsx"><i class="fa fa-download"></i> <span class="d-none d-md-inline-block">Download</span></button>
						</div>
					</div>
				</div>
				<div class="row mb-3" style="row-gap: 15px;">
					<div class="col-auto" v-for="bank in bank_list">
						<span class="btn border border-secondary rounded-pill pr-2 text-secondary">
							{{ bank.label }} <span class="cursor-pointer" @click="removeBank(bank.id)">&#128473;</span>
						</span>
					</div>
					<div class="col-auto" v-if="status_shipper !== ''">
						<span class="btn border border-secondary rounded-pill pr-2 text-secondary">
							{{ status_shipper }} <span class="cursor-pointer" @click="clearStatus()">&#128473;</span>
						</span>
					</div>
					<div class="col-auto" v-if="bank_list.length > 0 || status_shipper !== ''">
						<span class="btn btn-link px-0 text-secondary cursor-pointer font-weight-bold" @click="clearBank">
							Hapus Semua
						</span>
					</div>
				</div>
				<div class="table-responsive">
					<div class="btn-area" ref="btnSelected" style="display:none;">
						<button type="button" v-if="filter.status == 'request'" class="ml-2 btn btn-info"
							@click="prosesWithdraw" :disabled="processing">Proses</button>
						<button type="button" v-if="filter.status == 'proses'" class="ml-2 btn btn-warning text-light"
							@click="pendingWithdraw" :disabled="processing">Pending</button>
						<button type="button" v-if="filter.status == 'proses' || filter.status == 'pending'" class="ml-2 btn btn-success"
							@click="confirmWithdraw" :disabled="processing">Konfirmasi</button>
						<button type="button" v-if="filter.status == 'proses' || filter.status == 'pending'" class="ml-2 btn btn-danger"
							@click="tolakWithdraw" :disabled="processing">Tolak</button>
					</div>
					<table class="table table-hover" ref="tblsaldoapprove" id="tblwithdrawapprove">
						<thead>
						<tr>
							<th>
								<input type="checkbox" id="checkall" ref="checkall" name="checkall" value="1" @click="selectAll"/>
							</th>
							<th>ID</th>
							<th>SHIPPER</th>
							<th>JUMLAH TARIK TUNAI</th>
							<th>SALDO TERKINI SHIPPER</th>
							<th>DANA TERTAHAN</th>
							<th>REKENING</th>
							<th>BIAYA ADMIN</th>
							<th>TOTAL</th>
							<th>INVOICE ID</th>
							<th>TANGGAL</th>
							<th>STATUS</th>
							<th>APPROVAL</th>
							<th>TINDAKAN</th>
						</tr>
						</thead>
						<tbody @click="handleClick"></tbody>
					</table>
				</div>
			</div>
		</div>
		<!-- Manual Withdraw -->
		<div class="modal" tabindex="-1" role="dialog" ref="formPreview" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<form role="form" @submit.prevent="submitWithdraw">
				<input type="hidden" name="invoice_code" v-model="form.invoice_code">
				<input type="hidden" name="action" v-model="form.action">
				<div class="modal-content">
				<div class="modal-header">
					<h5>KONFIRMASI</h5>
				</div>
				<div class="modal-body">
					<div class="card bg-for-light">
					<span
						style="font-size: 15px; font-weight: 700; text-align: left"
						>Detail Penarikan</span
					>
					<table style="width: 100%; text-align: left">
						<tr class="items">
						<td style="width: 250px">Invoice ID</td>
						<td style="text-align: right">
							<div
							style="display: flex; justify-content: space-between"
							>
							<div></div>
							<div>{{ form.invoice_code }}</div>
							</div>
						</td>
						</tr>
						<tr class="items">
						<td style="width: 250px">Tanggal</td>
						<td style="text-align: right">
							<div
							style="display: flex; justify-content: space-between"
							>
							<div></div>
							<div>{{ form.deposit_date }}</div>
							</div>
						</td>
						</tr>
						<tr class="items">
						<td style="width: 250px">Shipper</td>
						<td style="text-align: right">
							<div
							style="display: flex; justify-content: space-between"
							>
							<div></div>
							<div>{{ form.shipper_name }}</div>
							</div>
						</td>
						</tr>
						<tr class="border-top">
						<td style="width: 250px">Total Penarikan</td>
						<td style="text-align: right">
							<div style="display: flex; justify-content: space-between">
							<div></div>
							<div>{{ formatPrice(form.credit) }}</div>
							</div>
						</td>
						</tr>
					</table>
					</div>
					<div class="bank card bg-for-light padding">
					<h6>Pilih Bank Untuk Penarikan:</h6>
					<div class="flex" style="padding: 10px">
						<v-select :options="totalBank" v-model="form.rekening_id" :reduce="opt => opt.rekening_id" />
					</div>
					<h6>Total Penarikan:</h6>
					<h4 class="amount">Rp {{ formatPrice(form.credit) }}</h4>
					</div>
				</div>
				<div class="modal-footer">
					<button type="submit" class="btn btn-primary">
						Konfirmasi
					</button>
					<button type="button" class="btn btn-secondary" data-dismiss="modal">
						Tutup
					</button>
				</div>
				<div class="mb-2"></div>
				</div>
				</form>
			</div>
		</div>
		
		<!-- Batch Withdraw -->
		<div class="modal" tabindex="-1" role="dialog" ref="formPreview2" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<form role="form" @submit.prevent="submitBatchWithdraw">
				<div class="modal-content">
				<div class="modal-header">
					<h5>KONFIRMASI PENARIKAN</h5>
				</div>
				<div class="modal-body">
					<div class="card bg-for-light">
					</div>
					<div class="bank card bg-for-light padding">
					<h6>Pilih Bank Untuk Penarikan:</h6>
					<div class="flex" style="padding: 10px">
						<v-select :options="totalBank" v-model="form.rekening_id" :reduce="opt => opt.rekening_id" />
					</div>
					<h6>Total penarikan dari {{ totalInvoice }} invoice :</h6>
					<h4 class="amount">Rp {{ formatPrice(totalPenarikan) }}</h4>
					</div>
				</div>
				<div class="modal-footer">
					<button type="submit" :disabled="processing" class="btn btn-primary">
						Konfirmasi
						<span v-if="processing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
					</button>
					<button type="button" class="btn btn-secondary" data-dismiss="modal">
						Tutup
					</button>
				</div>
				<div class="mb-2"></div>
				</div>
				</form>
			</div>
		</div>
		<div class="modal" tabindex="-1" role="dialog" ref="formFilter" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form @submit="getBank($event)">
						<div class="modal-header">
							<h5 class="modal-title font-weight-bold">Filter Withdraw</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="form-group m-2 p-3 bg-light rounded">
								<div class="row">
									<div class="col d-flex justify-content-between">
										<label class="h6">Bank Tujuan</label>
									</div>
								</div>
								<div class="row">
									<div class="col-auto col-sm-4" v-for="bank in totalBankShipper">
										<div class="icheck-material-orange icheck-inline form-check w-100">
											<input class="form-check-input" type="checkbox" :id="bank.code" :value="bank.id" v-model="filter.bank_id" />
											<label class="form-check-label w-100" :for="bank.code">{{ bank.label }}</label>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group m-2 p-3 bg-light rounded">
								<div class="row">
									<div class="col d-flex justify-content-between">
										<label class="h6">Status Shipper</label>
									</div>
								</div>
								<div class="row">
									<div class="form-group col" :class="{ 'col-sm-4' : status.id == 'null' ||  status.id == 'all'}" v-for="status in totalStatusShipper">
										<div class="icheck-material-orange icheck-inline">
											<input class="form-check-input" type="radio" name="status-shipper" :id="status.id" :value="status.code" v-model="filter.status_shipper" />
											<label class="form-check-label w-100" :for="status.id">{{ status.label }}</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-primary">Cari</button>
							<button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import $ from "jquery";
import Swal from "sweetalert2";
import moment from "moment";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import "icheck-material";

export default {
	nama: "DaftarWidthdrawSaldoApproval",
	data() {
	return {
		errors: [],
		status: [{label: 'Request', code: 'request'}, {label: 'Proses', code: 'proses'}, {label: 'Pending', code: 'pending'}, {label: 'Ditolak', code: 'tolak'}, {label: 'Sukses', code: 'sukses'}],
		totalStatusShipper: [
			{
				code: 'blacklist',
				id: 'blacklist',
				label: 'Blacklist'
			},
			{
				code: 'suspect',
				id: 'suspect',
				label: 'Suspect'
			},
			{
				code: 'suspect rekening',
				id: 'rekening',
				label: 'Suspect\xa0Rekening'
			},
			{
				code: 'null',
				id: 'null',
				label: 'Tanpa\xa0Status'
			},
			{
				code: 'all',
				id: 'all',
				label: 'Semua\xa0Status'
			}
		],
		status_shipper: "",
		method: "",
		form: {
			deposit_date: "",
			shipper_name: "",
			credit: "",
			invoice_code: "",
			rekening_id: "",
			action: "",
		},
		filter: {
			dt1: moment().startOf("month"),
			dt2: moment(),
			bank_id: [],
			status: "proses",
			status_shipper: "",
		},
		allWithdraw: {
			total: 0,
		},
		roles: "",
		role_id: "",
		totalBankShipper: [],
		totalBank: [],
		bank_list: [],
		deleting: false,
		processing: false,
		selectedCount: 0,
		totalPenarikan: [],
		totalInvoice: [],
		groupInvcode: [],
	};
	},
	watch:{
		$route (to, from){
			sessionStorage.filterData='';
			sessionStorage.filterStatus = '';
			this.filter = {};
			this.tbl.api().ajax.reload();
		}
	},
	created: function () {
		this.roles = this.$route.meta.roles;
		console.log("load initial data", this.$route);
		authFetch("/shipper/bank")
		.then((res) => {
			if (res.status === 201) {
			} else if (res.status === 400) {
			}
			return res.json();
		})
		.then((js) => {
			this.totalBankShipper = js.data;
			this.totalBankShipper.push({code: 'all-bank', id: '0', label: 'Semua Bank'});
		});
		this.loadBank();
		if(this.$route.params.filter) {
			this.filter = this.$route.params.filter;
		}
		if(this.filter.dt1 && this.filter.dt2)
		{
			this.dt1 = moment(this.filter.dt1);
			this.dt2 = moment(this.filter.dt2);
		}
		this.dateStart = this.dt1.format('YYYY-MM-DD');
		this.dateEnd = this.dt2.format('YYYY-MM-DD');
	},
	components: {
		vSelect,
	},
	methods: {
		onChange(event) {
			console.log(event.target.value);
		},
		selectStatus: function () {
			$(this.$refs.formFilter).modal("toggle");
		},
		onDate() {
			// var params = { dt1: this.dateStart, dt2: this.dateEnd };
			// var search = new URLSearchParams(params);
			this.tbl.api().ajax.reload();
		},
		confirmWithdraw: function () {
			const er = this.$refs;
			var checkboxes = document.querySelectorAll('.select-order');
			var selected = [];
			var total = 0;
			for (var i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].checked) {
					total += Number(checkboxes[i].dataset.credit);
					selected.push(checkboxes[i].value);
				}
			}
			this.totalPenarikan = total;
			this.totalInvoice = selected.length; //total yg di-centang
			this.groupInvcode = selected;
			this.form.rekening_id = "";
			$(er.formPreview2).modal("show");
		},
		submitBatchWithdraw: function (ev) {
			const er = this.$refs;
			// const self = this;
			if (!this.form.rekening_id) {
				Swal.fire("Rekening belum dipilih!", ``, "error");
			} else {
				var allData = { rekening_id: this.form.rekening_id, item: this.groupInvcode };
				this.processing = true;
				Swal.fire({
					title: "Konfirmasi Penarikan?",
					showCancelButton: true,
					confirmButtonText: `Ya`,
					cancelButtonText: "Tidak",
				}).then((result) => {
					if (result.isConfirmed) {
						authFetch("/payment/admin/confirm_withdraw", {
							method: 'POST',
							headers: {
								Accept: "application/json",
								"Content-Type": "application/json",
							},
							body: JSON.stringify(allData)
						})
						.then((res) => {
						return res.json();
						})
						.then((js) => {
						this.processing = false;
						if (js.success) {
							Swal.fire("Proses Berhasil", ``, "success");
							$(er.formPreview2).modal("hide");
							this.tbl.api().ajax.reload();
						} else if(js.errors) {
							detailData =
							'<div style="overflow-y: auto;max-height:150px;" class="error-detail border rounded p-2 mt-2 text-muted text-left">';
							for (let i in js.errors) {
							detailData +=
								"<b>Shipper " +
								js.errors[i].shipper +
								"</b><br><i>" +
								js.errors[i].msg +
								"</i><br>";
							}
							detailData += "</div>";
							var finalMsg =
							'<i class="fa fa-exclamation-triangle"></i> ' +
							js.msg +
							detailData;
							Swal.fire({
								title: "Data gagal diimport",
								icon: "error",
								html: finalMsg,
								showCloseButton: true,
							});
							this.tbl.api().ajax.reload();
						}
						else{
							Swal.fire("Proses gagal", `${js.msg}`, "error");
						}
						});
					}
				});
			}
			ev.preventDefault();
		},
		prosesWithdraw: function () {
			this.customSwal.fire({
				title: 'Proses?',
				showDenyButton: true,
				confirmButtonText: 'Lanjut',
				denyButtonText: 'Batal',
			}).then((result) => {
				if (result.isConfirmed) {
					var checkboxes = document.querySelectorAll('.select-order');
					var selected = [];
					for (var i = 0; i < checkboxes.length; i++) {
						if (checkboxes[i].checked) selected.push(checkboxes[i].value);
					}
					this.processing = true;
					authFetch(
						"/payment/admin/proses_withdraw", {
							method: 'POST',
							headers: {
								Accept: "application/json",
								"Content-Type": "application/json",
							},
							body: JSON.stringify(selected)
						}
					)
					.then((response) => response.json())
					.then((json) => {
						this.errors = [];
						this.processing = false;
						this.errors = [];
						if (json.success) {
							Swal.fire("Proses Berhasil", ``, "success");
							this.tbl.api().ajax.reload();
						} else if(json.errors) {
							let detailData = "";
							detailData =
							'<div style="overflow-y: auto;max-height:150px;" class="error-detail border rounded p-2 mt-2 text-muted text-left">';
							for (let i in json.errors) {
							detailData +=
								"<b>Shipper " +
								json.errors[i].shipper +
								"</b><br><i>" +
								json.msg +
								"</i><br>";
							}
							detailData += "</div>";
							var finalMsg =
							'<i class="fa fa-exclamation-triangle"></i> ' +
							json.msg +
							detailData;
							// Swal.fire("Proses gagal", `${js.msg}`, "error");
							Swal.fire({
								title: "Gagal Proses Withdraw",
								icon: "error",
								html: finalMsg,
								showCloseButton: true,
							});
							this.tbl.api().ajax.reload();
						}
						else {
							Swal.fire("Proses Gagal", json.desc, "error");
						}
					});
				}
			});
		},
		pendingWithdraw: function (e) {
			this.customSwal.fire({
				title: 'Pending?',
				showDenyButton: true,
				confirmButtonText: 'Lanjut',
				denyButtonText: 'Batal',
			}).then((result) => {
				if (result.isConfirmed) {
					var checkboxes = document.querySelectorAll('.select-order');
					var selected = [];
					for (var i = 0; i < checkboxes.length; i++) {
						if (checkboxes[i].checked) selected.push(checkboxes[i].value);
					}
					this.processing = true;
					authFetch(
						"/payment/admin/pending_withdraw", {
							method: 'POST',
							headers: {
								Accept: "application/json",
								"Content-Type": "application/json",
							},
							body: JSON.stringify(selected)
						}
					)
					.then((response) => response.json())
					.then((json) => {
						this.errors = [];
						this.processing = false;
						this.errors = [];
						if (json.success) {
							Swal.fire("Proses Berhasil", ``, "success");
							this.tbl.api().ajax.reload();
						} else if(json.errors) {
							let detailData = "";
							detailData =
							'<div style="overflow-y: auto;max-height:150px;" class="error-detail border rounded p-2 mt-2 text-muted text-left">';
							for (let i in json.errors) {
							detailData +=
								"<b>Shipper " +
								json.errors[i].shipper +
								"</b><br><i>" +
								json.msg +
								"</i><br>";
							}
							detailData += "</div>";
							var finalMsg =
							'<i class="fa fa-exclamation-triangle"></i> ' +
							json.msg +
							detailData;
							// Swal.fire("Proses gagal", `${js.msg}`, "error");
							Swal.fire({
								title: "Gagal Proses Withdraw",
								icon: "error",
								html: finalMsg,
								showCloseButton: true,
							});
							this.tbl.api().ajax.reload();
						}
						else {
							Swal.fire("Proses Gagal", json.desc, "error");
						}
					});
				}
			});
		},
		tolakWithdraw: function () {
			this.customSwal.fire({
				title: 'Tolak?',
				showDenyButton: true,
				confirmButtonText: 'Lanjut',
				denyButtonText: 'Batal',
			}).then((result) => {
				if (result.isConfirmed) {
					var checkboxes = document.querySelectorAll('.select-order');
					var selected = [];
					for (var i = 0; i < checkboxes.length; i++) {
						if (checkboxes[i].checked) selected.push(checkboxes[i].value);
					}
					this.processing = true;
					authFetch(
						"/payment/admin/cancel_withdraw", {
							method: 'POST',
							headers: {
								Accept: "application/json",
								"Content-Type": "application/json",
							},
							body: JSON.stringify(selected)
						}
					)
					.then((response) => response.json())
					.then((json) => {
						this.processing = false;
						if (json.success) {
							Swal.fire("Proses Berhasil", ``, "success");
							this.tbl.api().ajax.reload();
						} else {
							Swal.fire("Proses Gagal", json.desc, "error");
						}
					});
				}
			});
		},
		selectAll: function (e) {
			var val = e.target.checked;
			var checkboxes = document.querySelectorAll('.select-order');
			for (var i = 0; i < checkboxes.length; i++) {
				checkboxes[i].checked = val;
			}
			if (val && checkboxes.length > 0)
				this.selectedCount = checkboxes.length;
			else this.selectedCount = 0;
			if (this.selectedCount > 0) $(this.$refs.btnSelected).show();
			else $(this.$refs.btnSelected).hide();
		},
		getBank(event) {
			this.bank_list = [];
			for (const key of this.filter.bank_id) {
				this.bank_list.push(this.totalBankShipper.find((e) => e.id == key));
			}
			if (this.filter.status_shipper !== '') {
				this.status_shipper = this.totalStatusShipper.find((e) => e.code == this.filter.status_shipper).label;
			}
			this.tbl.api().ajax.reload();
			$(this.$refs.formFilter).modal("toggle");
			event.preventDefault();
		},
		removeBank(value) {
			this.bank_list = this.bank_list.filter((e) => e.id !== value);
			this.filter.bank_id = this.filter.bank_id.filter((e) => e !== value);
			this.tbl.api().ajax.reload();
		},
		clearBank() {
			this.bank_list = [];
			this.filter.bank_id = [];
			this.clearStatus();
		},
		clearStatus() {
			this.status_shipper = "";
			this.filter.status_shipper = "";
			this.tbl.api().ajax.reload();
		},
		getStatus() {
			this.tbl.api().ajax.reload();
		},
		loadBank: function () {
			let tipe = 'withdraw';
			authFetch("/payment/saldo/bank?tipe="+tipe)
			.then((res) => {
				if (res.status === 201) {} else if (res.status === 400) {}
				return res.json();
			})
			.then((js) => {
				this.totalBank = js.data;
			});
		},
		formatPrice(val){
			return formatCurrency(val);
		},
		getDataItem(aData)
		{
			return {
				deposit_date: aData.deposit_date,
				shipper_name: aData.shipper_name,
				credit: aData.credit,
				invoice_code: aData.invoice_code,
				rekening_id: '',
				action: 'confirm',
			}
		},
		submitWithdraw: function (ev) {
			const self = this;
			const er = this.$refs;
			if (!self.form.rekening_id) {
				Swal.fire("Rekening belum dipilih!", ``, "error");
			} else {
			var data = Object.keys(self.form)
			.map(
			(key) =>
				encodeURIComponent(key) + "=" + encodeURIComponent(self.form[key])
			)
			.join("&");
			let urlSubmit = "/payment/admin/approval/" + self.form.invoice_code;
	
			Swal.fire({
				title: "Konfirmasi Penarikan?",
				showCancelButton: true,
				confirmButtonText: `Ya`,
				cancelButtonText: "Tidak",
			}).then((result) => {
				if (result.isConfirmed) {
					authFetch(urlSubmit, {
						method: "PUT",
						body: data,
					})
					.then((res) => {
					return res.json();
					})
					.then((js) => {
					if (js.success) {
						Swal.fire("Proses Berhasil", ``, "success");
						$(er.formPreview).modal("hide");
						this.tbl.api().ajax.reload();
					} else {
						Swal.fire("Proses gagal", ``, "error");
						this.tbl.api().ajax.reload();
					}
					});
				}
			});
			}
			ev.preventDefault();
		},
		setUrl(id, title, data){
			Swal.fire({
			title: title,
			showCancelButton: true,
			confirmButtonText: `Ya`,
			cancelButtonText: "Tidak",
			}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				authFetch("/payment/admin/approval/" + id, {
				method: "PUT",
				body: data,
				})
				.then((res) => {
					return res.json();
				})
				.then((js) => {
					if (js.success) {
					Swal.fire("Proses Berhasil", ``, "success");
					this.tbl.api().ajax.reload();
					} else {
					Swal.fire("Proses gagal", ``, "error");
					this.tbl.api().ajax.reload();
					}
				});
			}
			});
		},
		downloadXlsx: function (e) {
			const self = this;
			self.processing = true;
	
			var table = $('#tblwithdrawapprove').DataTable();
			var length = table.page.info().recordsTotal;
			
			if (length > 14000) {
				Swal.fire("Proses gagal", `Maksimal data Withdraw sebanyak kurang lebih 14.000 data.`, "error");
				self.processing = false;
				return false;
			}
			
			var filter = JSON.parse(JSON.stringify(self.filter));
				filter.q = $("input[name=q]").val();
				filter.category = $("select[name=category]").val();
	
			console.log(filter);
			var data = Object.keys(filter)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
				)
				.join("&");
		// alert(data);
		// return;
	
			authFetch("/report/withdraw/excell", {
				method: "POST",
				body: data,
			}).then((response) => response.blob())
			.then((blob) => {
			setTimeout(() => {
				self.processing = false;
			}, 1000);
			var url = window.URL.createObjectURL(blob);
			var a = document.createElement("a");
			a.href = url;
			a.download = "withdraw.xlsx";
			document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
			a.click();
			a.remove(); //afterwards we remove the element again
			});
		},
		handleClick(e) {
			const er = this.$refs;
			if (e.target.matches("button")) {
				let id = e.target.dataset.id;
				let title = '';
				this.form.action = e.target.dataset.action;
				var data = Object.keys(this.form)
				.map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])).join("&");
			if (e.target.dataset.action == "confirm") {
				var nRow = $(e.target).closest("tr").get(0);
				var iRow = this.tbl.fnGetPosition(nRow);
				var aData = this.tbl.fnGetData(iRow);
				this.form = this.getDataItem(aData);
				$(er.formPreview).modal("show");
			}else if(e.target.dataset.action == "proses"){
				title = 'Proses?';
				this.setUrl(id, title, data);
			} else if(e.target.dataset.action == "tolak"){
				title = 'Tolak?';
				this.setUrl(id, title, data);
			}
			return false;
			} else if (e.target.matches("input[type=checkbox]")) {
				var checkboxes = document.querySelectorAll('.select-order:checked');
				if (checkboxes.length == 0) {
					this.$refs.checkall.checked = false;
				} else {
					this.$refs.checkall.checked = true;
				}
				this.selectedCount = checkboxes.length;
				if (this.selectedCount > 0) $(this.$refs.btnSelected).show();
				else $(this.$refs.btnSelected).hide();
				return false;
			}
		},
	},
	mounted() {
		const e = this.$refs;
		const self = this;
		new Daterangepicker(
			this.$refs.daterange,
			{
				startDate: !this.dt1 ? moment().day(-31) : this.dt1,
				endDate: !this.dt2 ? moment() : this.dt2,
				locale: {
					format: 'DD/MM/YYYY'
				},
			},
			function (d1, d2) {
				self.dateStart = d1.format("YYYY-MM-DD");
				self.dateEnd = d2.format("YYYY-MM-DD");
				// self.data_type = self.data_type;
				// self.filter.datatype = self.data_type;
				self.filter.dt1 = self.dateStart;
				self.filter.dt2 = self.dateEnd;
				self.onDate();
			}
		);
		this.tbl = createTable(e.tblsaldoapprove, {
			ajax: "/payment/admin/withdraw",
			title: "",
			roles: this.$route.params.roles,
			selectedRowClass: "",
			serverSide: true,
			frame: false,
			displayLength: 10,
			lengthMenu: [[ 10, 25, 50, 100, 1000, -1 ], [ 10, 25, 50, 100, 1000, 'Semua' ]],
			lengthChange: true,
			processing: true,
			toolbarEl: e.toolbar,
			// scrollX: true,
			dom: "<'row'<'col-sm-12 col-md-6 btn-area-selected'><'col-sm-12 col-md-6'f>>" +
					"<'row'<'col-sm-12'tr>>" +
					"<'row'<'col-sm-12 col-md-2'l><'col-sm-12 col-md-3'i><'col-sm-12 col-md-7'p>>",
			order: [[ 10, "desc" ]],
			language: {
				processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
			paramData: function (d) {
				var filter = JSON.parse(JSON.stringify(self.filter));
				d.dt1 = self.dateStart;
				d.dt2 = self.dateEnd;
				// d.datatype = self.data_type;
				for (const key in filter) {
					d[key] = filter[key] === false ? 0: filter[key];
				}
			},
			columns: [
			{
				sortable: false,
				"data": "checkall", // can be null or undefined
				"defaultContent": '<input type="checkbox">',
				render: function (data, type, row, meta) {
					return (
						'<input type="checkbox" class="select-order" data-credit="' + row.credit +'" value="' + row.invoice_code + '" />'
					);
				},
			},
			{ data: "shipper_code" },
			{ data: "shipper_name",
				render: function (data, type, row, meta) {
					return (
						row.marking? ('<div>'+data+'</div><div><span class="badge badge-info">'+row.marking+'</span></div>') : data
					);
				}
			},
			{ data: "biaya_transfer", render: function (data, type, row, meta) {
				return "Rp " + formatCurrency(data);
			}, },
			{
				sortable: false,
				data: "saldo_terkini", visible: false, render: function (data, type, row, meta) {
				return "Rp " + formatCurrency(data);
				},
			},
			{
				sortable: false,
				data: "dana_tertahan", visible: false, render: function (data, type, row, meta) {
				return "Rp " + formatCurrency(data);
				},
			},
			{
				data: "bank", width: "110px",
				render: function (data, type, row, meta) {
					if (!data) {
						return "";
					}
					return `<span>${data}</span>`;
				}
			},
			{ data: "biaya_admin", render: function (data, type, row, meta) {
				return "Rp " + formatCurrency(data);
			}, },
			{ data: "credit", render: function (data, type, row, meta) {
				return "Rp " + formatCurrency(data);
			}, },
			{ data: "invoice_code" },
			{ data: "created_on",
			render: function (data, type, row, meta) {
				return !data ? '-' : moment(data).format("DD/MM/YYYY HH:mm");
			}, },
			{ data: "status", render: function (data, type, row, meta) {
				let status = '';
				if(row.active < 1)
				{
					return `<span class="badge badge-danger">DITOLAK</span>`;
				} else {
					if (row.status == 0) {
						status = `<span class="badge badge-light">REQUEST</span>`;
					} else if (row.status == 1) {
						status = `<span class="badge badge-success">SUKSES</span>`;
					} else if (row.status == 2) {
						status = `<span class="badge badge-info">PROSES</span>`;
					} else if (row.status == 3) {
						status = `<span class="badge badge-warning text-light">PENDING</span>`;
					}
				}
				return status;
			} },
			{ data: "approved_on",
			render: function (data, type, row, meta) {
				return !data ? '-' : moment(data).format("DD/MM/YYYY HH:mm");
			}, },
			{ data: "action", visible: false, render: function (data, type, row, meta) {
				let act = "";
				if (row.status == 1 || row.active < 1) {
					act = "";
				} else if(row.status == 0) {
					act = `<div class="btn-group">
				<button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				Klik
				</button>
				<div class="dropdown-menu dropdown-menu-bottom">
				<button type="button" class="btn btn-sm btn-warning dropdown-item" data-action="proses" data-id="` + row.invoice_code + `">Proses</button>
				<button type="button" class="btn btn-sm btn-success dropdown-item" data-action="confirm" data-id="` +
						row.invoice_code +
						`"> Konfirmasi </button>
				<button type="button" class="btn btn-sm btn-danger dropdown-item" data-action="tolak" data-id="` +
						row.invoice_code +
						`"> Tolak </button>
				</div>
				</div>`;
				} else if(row.status == 2) {
					act = `<div class="btn-group">
				<button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				Klik
				</button>
				<div class="dropdown-menu dropdown-menu-bottom">
				<button type="button" class="btn btn-sm btn-success dropdown-item" data-action="confirm" data-id="` +
						row.invoice_code +
						`"> Konfirmasi </button>
				<button type="button" class="btn btn-sm btn-danger dropdown-item" data-action="tolak" data-id="` +
						row.invoice_code +
						`"> Tolak </button>
				</div>
				</div>`;
				}
				return act;
			} },
			],
			filterBy: [1, 2, 3, 6, 9],
			rowCallback: function (row, data) {
				// alert('rowCallback');
			},
			drawCallback: function (row, data) {
				self.allWithdraw.total = row._iRecordsTotal;
				self.$refs.checkall.checked = false;
			},
			initComplete: function () {
				// alert('initComplete');
				$(e.btnSelected).appendTo('.btn-area-selected');
				$('.loading-overlay').removeClass('show');
			},
		});
		this.customSwal = Swal.mixin({
			customClass: {
				popup: 'w-fit',
				title: 'h4 tex-dark',
				actions: 'd-flex justify-content-between flex-row-reverse',
				confirmButton: 'btn btn-primary w-49 font-weight-bold py-2',
				denyButton: 'btn btn-outline-primary w-49 font-weight-bold py-2',
				cancelButton: 'btn btn-primary btn-block font-weight-bold py-2',
			},
			buttonsStyling: false
		});
	},
};
</script>
<style scoped>
.bank {
	position: relative;
	display: block;
	padding: 10px;
	margin: 0 10px 10px;
	text-align: center;
}
.onebank {
	display: inline-block;
	text-align: center;
	width: 50%;
}

.onebank img {
	width: 130px;
	margin: 10px;
	padding-top: 10px;
}

.bankdetails {
	padding: 10px;
}

.bankname {
	font-weight: 600;
	font-size: 15px;
}

.amount,
.bankdetails .number {
	color: #2e47ba;
}

.banklabel {
	width: 100%;
	font-size: 1rem;
}

.card-input-element+.card {
	height: calc(36px + 2*1rem);
	color: #E05119;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 2px solid transparent;
	border-radius: 4px;
}

.card-input-element+.card:hover {
	cursor: pointer;
}

.card-input-element:checked+.card {
	border: 2px solid #E05119;
	-webkit-transition: border .3s;
	-o-transition: border .3s;
	transition: border .3s;
}

.card-input-element:checked+.card::after {
	content: '\2713';
	color: #E05119;
	font-size: 24px;
	-webkit-animation-name: fadeInCheckbox;
	animation-name: fadeInCheckbox;
	-webkit-animation-duration: .5s;
	animation-duration: .5s;
	-webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@-webkit-keyframes fadeInCheckbox {
	from {
		opacity: 0;
		-webkit-transform: rotateZ(-20deg);
	}
	to {
		opacity: 1;
		-webkit-transform: rotateZ(0deg);
	}
}

@keyframes fadeInCheckbox {
	from {
		opacity: 0;
		transform: rotateZ(-20deg);
	}
	to {
		opacity: 1;
		transform: rotateZ(0deg);
	}
}

.dataTables_length{padding-top: 0.5em;}
</style>